import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Loading from "../../../components/Loading";
import DateFormatter from "../../../components/DateFormatter";

function AdminUsers() {
    const [users, setUsers] = useState(null);
    const [error, setError] = useState(null);

    const refreshUsers = useCallback(async () => {
        try {
            const response = await axios.post("/admin/getUsers");
            setUsers(response.data.users);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, []);

    useEffect(() => {
        refreshUsers();
    }, [refreshUsers]);

    return (
        <React.Fragment>
            <Title preTitle="Overzicht" noBottom={!error}>
                Gebruikers
            </Title>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <Table hover>
                    <thead>
                        <tr className="tr-sticky">
                            <th className="text-center">#</th>
                            <th className="text-left">Naam</th>
                            <th className="text-left">Email</th>
                            <th className="text-right">Registratie datum</th>
                            <th className="text-right">Laatste activiteit</th>
                        </tr>
                    </thead>
                    <tbody>
                        { !users ? (
                            <tr>
                                <td colSpan="3">
                                    <Loading/>
                                </td>
                            </tr>
                        ) : users.map((user) => {
                            const url = `/admin/user/${user.id}`;
                            return (
                                <tr
                                    key={ user.id }
                                    className="table-clickable-row"
                                >
                                    <th className="align-middle text-center">
                                        <Link to={ url }>
                                            { user.id }
                                        </Link>
                                    </th>
                                    <td className="align-middle">
                                        <Link to={ url }>
                                            { user.name }
                                        </Link>
                                    </td>
                                    <td className="align-middle">
                                        <Link to={ url }>
                                            { user.email }
                                        </Link>
                                    </td>
                                    <td className="align-middle text-right">
                                        <Link to={ url }>
                                            <DateFormatter date={user.registerDate} format="DD-MM-YYYY"/>
                                        </Link>
                                    </td>
                                    <td className="align-middle text-right">
                                        <Link to={ url }>
                                            { user.lastActivity ? (
                                                <DateFormatter date={user.lastActivity} format="DD-MM-YYYY"/>
                                            ) : "Onbekend"}
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
        </React.Fragment>
    );
}

export default React.memo(AdminUsers);
