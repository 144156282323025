import React from "react";

import DetailOverviewRow from "../../../../components/DetailOverviewRow";
import DateFormatter from "../../../../components/DateFormatter";

function AdminUserDetailOverview({ user }) {
    if(!user) {
        return null;
    }
    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-borderless mb-0">
                    <tbody>
                        <DetailOverviewRow title="Naam">
                            { user.name }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Email">
                            { user.email }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Global admin">
                            { user.globalAdmin ? (
                                <i className="fa-solid fa-check text-success fa-fw"/>
                            ) : (
                                <i className="fa-solid fa-times text-danger fa-fw"/>
                            ) }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Show debug">
                            { user.showDebug ? (
                                <i className="fa-solid fa-check text-success fa-fw"/>
                            ) : (
                                <i className="fa-solid fa-times text-danger fa-fw"/>
                            ) }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Registratie datum">
                            <DateFormatter date={ user.registerDate }/>
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Laatste login">
                            { user.lastLogin ? (
                                <DateFormatter date={ user.lastLogin }/>
                            ) : (
                                <i className="text-muted">Onbekend</i>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Laatste activiteit">
                            { user.lastActivity ? (
                                <DateFormatter date={ user.lastActivity }/>
                            ) : (
                                <i className="text-muted">Onbekend</i>
                            )}
                        </DetailOverviewRow>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default React.memo(AdminUserDetailOverview);
