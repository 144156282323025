import React, {
    useCallback,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";

function AdminUserDetailAvatar({ user, refreshUser }) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onClickRemoveAvatar = useCallback(async () => {
        setError(null);
        setLoading(true);
        try {
            await axios.post("/admin/removeUserAvatar", { userId: user.id });
            await refreshUser();
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [user, refreshUser]);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div
                className="card mb-3"
                style={{
                    maxWidth: "500px",
                }}
            >
                { user.avatar ? (
                    <img
                        src={ user.avatar.url }
                        className="card-img-top"
                        alt={ user.name }
                    />
                ) : (
                    <div className="card-body">
                        <p className="card-text">
                            Gebruiker heeft geen avatar ingesteld.
                        </p>
                    </div>
                )}
            </div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">
                        Admin functies
                    </h5>
                    <Button
                        variant="danger"
                        onClick={onClickRemoveAvatar}
                        disabled={loading || !user.avatar}
                    >
                        { loading ? (
                            <Spinner animation="border" size="sm" className="mr-2"/>
                        ) : (
                            <i className="fa-solid fa-trash mr-2"/>
                        )}
                        Verwijder avatar
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(AdminUserDetailAvatar);
