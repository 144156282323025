import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Route,
    Switch,
    useParams,
    useRouteMatch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../../../components/Helmet";
import Loading from "../../../../components/Loading";

import AdminUserDetailOverview from "./AdminUserDetailOverview";
import AdminUserDetailAvatar from "./AdminUserDetailAvatar";
import AdminUserDetailEdit from "./AdminUserDetailEdit";

function AdminUserDetail() {
    const { userId } = useParams();
    const match = useRouteMatch();
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    const refreshUser = useCallback(async () => {
        setUser(null);
        setError(null);
        try {
            const response = await axios.post("/admin/getUser", { userId });
            setUser(response.data.user);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [userId]);

    useEffect(() => {
        refreshUser();
    }, [refreshUser]);

    const baseUrl = match.url;

    const props = {
        userId,
        user,
        refreshUser,
        setUser,
    };

    return (
        <React.Fragment>
            <Helmet title={ user?.name ?? "Gebruiker" }/>
            <Title preTitle="Detail" noBottom={user !== null}>
                { user?.name ?? "Gebruiker" }
            </Title>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !user ? (
                <Loading/>
            ) : (
                <React.Fragment>
                    <TabBar>
                        <TabBarItem to={baseUrl}>
                            Overzicht
                        </TabBarItem>
                        <TabBarItem to={ `${baseUrl}/avatar` }>
                            Avatar
                        </TabBarItem>
                        <TabBarItem to={ `${baseUrl}/edit` }>
                            Bewerken
                        </TabBarItem>
                    </TabBar>
                    <Switch>
                        <Route path={ `${baseUrl}` } exact>
                            <AdminUserDetailOverview {...props}/>
                        </Route>
                        <Route path={ `${baseUrl}/avatar` }>
                            <AdminUserDetailAvatar {...props}/>
                        </Route>
                        <Route path={ `${baseUrl}/edit` }>
                            <AdminUserDetailEdit {...props}/>
                        </Route>
                    </Switch>
                </React.Fragment>
            ) }
        </React.Fragment>
    );
}

export default React.memo(AdminUserDetail);
